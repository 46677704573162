<template>
    <div class="row">
        <div class="font-weight-bolder text-left col-12 my-2 h4">篩選器</div>
        <div v-for="(condition, index) in filterSelects" :key="index" class="col-3 mb-2">
            <v-select
                v-model="params[condition.filed]"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :placeholder="condition.title"
                :options="condition.options"
                :reduce="(option) => option.id"
                :multiple="condition.multiple"
            />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-evenly flex-wrap mb-2" style="gap: 16px">
                <template v-for="(option, index) in filterCheckbox">
                    <b-checkbox :key="index" v-model="params[option.filed]">
                        {{ option.title }}
                    </b-checkbox>
                </template>
            </div>
            <button type="button" class="btn btn-primary mb-2" @click="onSubmit">過濾</button>
            <hr />
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import survey from '@/api/survey'

export default {
    name: 'FilterTemplates',
    components: {
        vSelect,
    },
    data() {
        return {
            params: {},
            filterCheckbox: [
                {
                    title: '免費',
                    filed: 'free',
                    value: false,
                },
                {
                    title: '熱門',
                    filed: 'hot',
                    value: false,
                },
                {
                    title: '最新',
                    filed: 'latest',
                    value: false,
                },
            ],
            filterSelects: [],
        }
    },
    async mounted() {
        const [colors, themes, industries, websiteTypes] = await Promise.all([
            survey.color(),
            survey.theme(),
            survey.industry(),
            survey.websiteType(),
        ])

        this.filterSelects.push({
            title: '架站目的',
            filed: 'website_type',
            multiple: false,
            options: websiteTypes.data.data,
        })

        this.filterSelects.push({
            title: '產業類別',
            filed: 'industry',
            multiple: false,
            options: industries.data.data,
        })

        this.filterSelects.push({
            title: '設計風格',
            filed: 'theme',
            multiple: true,
            options: themes.data.data,
        })

        this.filterSelects.push({
            title: '顏色',
            filed: 'color',
            multiple: true,
            options: colors.data.data,
        })
    },
    methods: {
        onSubmit() {
            let params = { ...this.params }

            Object.keys(params).forEach((key) => {
                if (params[key] instanceof Array) {
                    params[key] = params[key].join(',')
                }
            })

            this.$emit('submit', params)
        },
    },
}
</script>

<style lang="scss" scoped></style>
