<template>
    <div class="text-center my-5">
        <h1 class="font-weight-bolder">需要專人協助嗎 ?</h1>
        <p class="card-text mx-auto my-2 w-75">專業顧問團隊全面解答開店疑惑，現在就開始打造理想的網站</p>
        <button type="button" class="btn btn-primary">立即咨詢</button>
    </div>
</template>

<script>
export default {
    name: 'Help',
}
</script>

<style scoped></style>
