<template>
    <div class="card-header bg-light-primary rounded-top text-center pt-5 pb-3">
        <div class="w-100">
            <h1 class="mt-5 font-weight-bolder">隨心所欲打造你理想的網站</h1>
            <p class="card-text mx-auto my-2 w-75">Discover inspiring portfolios, presentations, mockups, and more.</p>
            <div class="w-50 mb-3 mx-auto">
                <b-tabs v-model="tabIndex" fill class="w-100">
                    <b-tab v-for="(tab, index) in tabs" :key="index" :disabled="tab.disabled">
                        <template #title>
                            <feather-icon :icon="tab.icon" />
                            <span>{{ tab.title }}</span>
                        </template>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        tabs: {
            type: Array,
            default: () => [
                {
                    title: '風格版型',
                    icon: 'LayoutIcon',
                    disabled: false,
                },
                {
                    title: '功能模組',
                    icon: 'ToolIcon',
                    disabled: true,
                },
                {
                    title: '插件工具',
                    icon: 'PlusSquareIcon',
                    disabled: false,
                },
            ],
        },
    },
    data() {
        return {
            tabIndex: 0,
        }
    },
    watch: {
        tabIndex() {
            this.$emit('tabChange', this.tabIndex)
        },
    },
}
</script>

<style scoped></style>
