<template>
    <div>
        <template v-for="(plugin, index) in plugins">
            <div :key="index" class="row">
                <h2 class="col-12 mt-5 mb-2 font-weight-bolder">{{ plugin.title }}</h2>
                <template v-for="(component, idx) in plugin.components">
                    <div :key="idx" class="col-3">
                        <div class="card border">
                            <div class="card-body">
                                <div class="d-flex align-items-center card-title">
                                    <feather-icon class="mr-2" :icon="component.icon" />
                                    <span class="">{{ component.title }}</span>
                                </div>
                                <ul>
                                    <li v-for="(item, i) in component.items" :key="i" class="text-left">
                                        <a :href="item.url">{{ item.title }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <slot name="footer" />
    </div>
</template>

<script>
export default {
    name: 'Plugin',
    props: {
        plugins: {
            type: Array,
            default: () => [
                {
                    title: '我們覺得這些插件適合你',
                    components: [
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-1.jpg',
                            title: '插件名稱1',
                            items: [
                                {
                                    title: '插件名稱1',
                                    url: 'https://www.google.com',
                                },
                                {
                                    title: '插件名稱2',
                                    url: 'https://www.google.com',
                                },
                                {
                                    title: '插件名稱3',
                                    url: 'https://www.google.com',
                                },
                            ],
                        },
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-2.jpg',
                            title: '插件名稱2',
                            items: [
                                {
                                    title: '插件名稱1',
                                    url: 'https://www.google.com',
                                },
                                {
                                    title: '插件名稱2',
                                    url: 'https://www.google.com',
                                },
                                {
                                    title: '插件名稱3',
                                    url: 'https://www.google.com',
                                },
                            ],
                        },
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-3.jpg',
                            title: '插件名稱3',
                            items: [
                                {
                                    title: '插件名稱1',
                                    url: 'https://www.google.com',
                                },
                                {
                                    title: '插件名稱2',
                                    url: 'https://www.google.com',
                                },
                                {
                                    title: '插件名稱3',
                                    url: 'https://www.google.com',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
}
</script>

<style scoped></style>
