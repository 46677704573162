<template>
    <div class="row match-height">
        <div class="col-12">
            <div class="card card text-center">
                <Tabs v-if="config.tabComponent.status" :tabs="config.tabComponent.tabs" @tabChange="tabChange" />
                <div class="card-body px-3">
                    <FilterTemplates v-if="config.filterComponent.status" @submit="onFilterSubmit" />
                    <Templates v-if="config.templateComponent.status" :templates="config.templateComponent.templates">
                        <template #header>
                            <h2 v-if="config.templateComponent.slotHeader.title" class="mt-5 mb-2 font-weight-bolder">
                                {{ config.templateComponent.slotHeader.title }}
                            </h2>
                        </template>
                        <template #footer>
                            <b-pagination
                                v-if="config.templateComponent.slotFooter.pagination"
                                v-model="currentPage"
                                class="my-2"
                                :total-rows="meta.total"
                                :per-page="meta.count"
                                size="lg"
                                align="center"
                            />
                            <button
                                v-if="config.templateComponent.slotFooter.more"
                                type="button"
                                class="btn btn-primary my-2"
                                @click="tabChange(0)"
                            >
                                選擇更多版型
                            </button>
                        </template>
                    </Templates>
                    <Features v-if="config.featureComponent.status" :features="config.featureComponent.features" />
                    <Plugins v-if="config.pluginComponent.status" :plugins="config.pluginComponent.plugins" />
                </div>
                <Help v-if="config.helpComponent.status" />
            </div>
        </div>
    </div>
</template>

<script>
import Templates from '@/views/templates-market/Templates'
import Help from '@/views/templates-market/Help'
import FilterTemplates from '@/views/templates-market/FilterTemplates'
import Tabs from '@/views/templates-market/Tabs'
import Features from '@/views/templates-market/Features'
import config0 from '@/views/templates-market/config0'
import config1 from '@/views/templates-market/config1'
import config2 from '@/views/templates-market/config2'
import config3 from '@/views/templates-market/config3'
import Plugins from '@/views/templates-market/Plugins'
import survey from '@/api/survey'

export default {
    name: 'Market',
    components: {
        Plugins,
        Tabs,
        FilterTemplates,
        Templates,
        Help,
        Features,
    },
    data() {
        return {
            config: config0,
            currentPage: 1,
            meta: {
                // count: 9,
                // total: 0,
            },
        }
    },
    watch: {
        $route() {
            this.fetchTemplate()
        },
        currentPage() {
            this.fetchTemplate()
        },
    },
    created() {},
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            await this.fetchTemplate()
        },
        tabChange(index) {
            this.$router.replace({ query: null })

            switch (index) {
                case 0:
                    // 選擇更多版型
                    this.config = config1
                    this.init()
                    break
                case 1:
                    // 功能模組
                    this.config = config2
                    break
                case 2:
                    // 插件工具
                    this.config = config3
                    break
            }
        },
        onFilterSubmit(params) {
            this.$router.push({ query: params })
        },
        async fetchTemplate() {
            const params = {
                ...this.$route.query,
                count: this.meta.count,
                page: this.currentPage,
            }
            console.log(params)
            await survey.template(params).then((res) => {
                this.config.templateComponent.templates = res.data.data.rows
                this.meta.total = res.data.data.meta.total
            })
        },
    },
}
</script>

<style scoped></style>
