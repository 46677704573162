<template>
    <div>
        <slot name="header" />
        <div class="row">
            <template v-for="(template, index) in templates">
                <div :key="index" class="col-4">
                    <div class="card py-4 px-2">
                        <img :src="template.preview_thumbnail" alt="" />
                        <div class="card-body">
                            <h4 class="card-title">{{ template.name }}</h4>
                            <!--                                <p class="card-text">{{ template.description }}</p>-->
                        </div>
                    </div>
                    <div class="blockElm">
                        <button type="button" class="btn btn-secondary" @click="openWindow(template.preview_url)">
                            預覽
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="$router.push({ name: 'building-website', query: { template_id: template.id } })"
                        >
                            試做
                        </button>
                    </div>
                </div>
            </template>
        </div>
        <slot name="footer" />
    </div>
</template>

<script>
export default {
    name: 'Templates',
    props: {
        templates: {
            type: Array,
            default: () => [
                {
                    title: '我們覺得這些版型適合你',
                    components: [
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-1.jpg',
                            title: '版型名稱1',
                            description: '形象網站',
                        },
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-2.jpg',
                            title: '版型名稱2',
                            description: '部落格',
                        },
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-3.jpg',
                            title: '版型名稱3',
                            description: '店商',
                        },
                    ],
                },
            ],
        },
    },
    data() {
        return {}
    },
    methods: {
        openWindow(url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
.blockElm:hover {
    background-color: rgba(0, 0, 0, 0.2);
    transition-duration: 0.2s;
    button {
        opacity: 1;
    }
}
.blockElm {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    button {
        opacity: 0;
    }
}
</style>
