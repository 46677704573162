import axiosIns from '@/libs/axios'

export default {
    websiteCreate: (data) => axiosIns.post('/website/create', data),
    templateCategories: (template_id) => axiosIns.get('/template/' + template_id + '/categories'),
    preference: () => axiosIns.get('/survey/preference'),
    surveyRecords: (data) => axiosIns.post('/survey/records', data),
    template: (data) => axiosIns.get('/template/recommend', { params: data }),
    industry: () => axiosIns.get('/global/industries'),
    theme: () => axiosIns.get('/global/themes'),
    color: () => axiosIns.get('/global/colors'),
    websiteType: () => axiosIns.get('/global/website-types'),
}
