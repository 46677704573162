<template>
    <div>
        <template v-for="(feature, index) in features">
            <div :key="index" class="row">
                <h2 class="col-12 mt-5 mb-2 font-weight-bolder">{{ feature.title }}</h2>
                <template v-for="(component, idx) in feature.components">
                    <div :key="idx" class="col-4">
                        <div class="card p-2 border">
                            <img :src="component.image" alt="" />
                            <div class="card-body px-0">
                                <h4 class="card-title">{{ component.title }}</h4>
                                <p class="card-text">{{ component.description }}</p>
                                <b-button class="mb-2" variant="primary">Deom</b-button>
                                <div class="d-flex justify-content-between align-items-center">
                                    <b-form-rating
                                        v-model="component.rating"
                                        class="px-0"
                                        no-border
                                        show-value
                                        inline
                                        variant="warning"
                                        readonly
                                    />
                                    <strong>{{ component.purchases }} 次購買</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <slot name="footer" />
    </div>
</template>

<script>
export default {
    name: 'Feature',
    props: {
        features: {
            type: Array,
            default: () => [
                {
                    title: '我們覺得這些版型適合你',
                    components: [
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-1.jpg',
                            title: '版型名稱1',
                            description: '形象網站',
                        },
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-2.jpg',
                            title: '版型名稱2',
                            description: '部落格',
                        },
                        {
                            image: 'https://apro-br.com/archive/images/program_s5_img/new-3.jpg',
                            title: '版型名稱3',
                            description: '店商',
                        },
                    ],
                },
            ],
        },
    },
}
</script>

<style scoped></style>
